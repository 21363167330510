import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Button,
  TextField,
  RadioGroup,
  Radio,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import InputText from "../../Fields/InputText";
import Signatory from "../Customer/Signatory";
import "../css/NewDealer.css";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
const socialSecurityNumberCountries = ["se", "dk", "no", "fi"];

export default class NewDealerFloorplan extends React.Component {
  static propTypes = {
    newDealer: PropTypes.object,
    labels: PropTypes.object,
    changeHandler: PropTypes.func,
    formInformation: PropTypes.object,
    checkHandler: PropTypes.func,
    params: PropTypes.object,
    addSignatory: PropTypes.func,
    removeSignatory: PropTypes.func,
    payment: PropTypes.bool,
  };

  state = {
    newDealerName: false,
    organizationNumber: false,
    postAddress: false,
    nd_zipCode: false,
    nd_city: false,
    contactPerson: false,
    phoneContactPerson: false,
    emailContactPerson: false,
    invoiceEmail: false,
    differentInvoiceAddress: false, // checkbox
    invoiceAddress: false,
    invoiceZipCode: false,
    invoiceCity: false,
    wantEInvoice: false, // checkbox
    differentEInvoiceEmail: false, // checkbox
    eInvoiceEmail: false,
    bankGiroPostGiro: false,
    bankAccountNumber: false,
    bicSwiftCode: false,
    clearingNumber: false,
    ibanNumber: false,
    bankName: false,
    husqvarnaNumber: false,
    hasHusqvarnaPortalUser: false, // checkbox
    husqvarnaUserFirstName: false,
    husqvarnaUserLastName: false,
    husqvarnaUserEmail: false,
    husqvarnaUserId: false,
    desiredService: false,
    hasOwnershipOver25Percent: false, // checkbox
    signatory: false,
    beneficialOwner: false,
  };

  componentDidMount() {
    this.setState(this.checkFieldsFinished());
  }

  checkFieldsFinished = () => {
    let newState = { ...this.state };
    console.log("state: ", newState);
    console.log("newdealer: ", this.props.newDealer);

    const checkboxes = [
      "differentInvoiceAddress",
      "wantEInvoice",
      "differentEInvoiceEmail",
      "hasHusqvarnaPortalUser",
      "hasOwnershipOver25Percent",
    ];

    const checkboxDependencies = {
      differentInvoiceAddress: [
        "invoiceAddress",
        "invoiceZipCode",
        "invoiceCity",
      ],
      differentEInvoiceEmail: ["eInvoiceEmail"],
      hasHusqvarnaPortalUser: [
        "husqvarnaUserFirstName",
        "husqvarnaUserLastName",
        "husqvarnaUserEmail",
        "husqvarnaUserId",
      ],
    };

    for (let key in newState) {
      if (checkboxes.includes(key)) {
        if (this.props.newDealer[key] === true) {
          // Checkbox is checked
          newState[key] = true;
          console.log(key);

          if (checkboxDependencies[key]) {
            checkboxDependencies[key].forEach((dependentField) => {
              newState[dependentField] =
                this.props.newDealer[dependentField] !== undefined &&
                this.props.newDealer[dependentField] !== "" &&
                this.props.newDealer[dependentField] !== null;
            });
          }
        } else {
          // Checkbox is not checked
          newState[key] = true;
          if (checkboxDependencies[key]) {
            checkboxDependencies[key].forEach((dependentField) => {
              newState[dependentField] = true;
            });
          }
        }
      } else if (!Object.values(checkboxDependencies).flat().includes(key)) {
        // For non-checkbox fields that are not dependencies of any checkbox
        newState[key] =
          this.props.newDealer[key] !== undefined &&
          this.props.newDealer[key] !== "" &&
          this.props.newDealer[key] !== null;
      }
    }

    let signatories = this.props.newDealer.newDealerSignatories;
    newState["signatory"] = this.signatoryFinished(signatories);
    if (this.props.newDealer.hasOwnershipOver25Percent) {
      newState["beneficialOwner"] = this.beneficialOwnerFinished(
        this.props.newDealer.beneficialOwners
      );
    } else {
      newState["beneficialOwner"] = true;
    }

    if (this.props.newDealer.phoneContactPerson.length < 5) {
      newState["phoneContactPerson"] = false;
    }
    return newState;
  };

  beneficialOwnerFinished = (owners) => {
    let beneficialOwner = true;
    for (const key1 in owners) {
      for (const key2 in owners[key1]) {
        if (
          owners[key1][key2] === undefined ||
          owners[key1][key2] === "" ||
          owners[key1][key2] === null
        ) {
          beneficialOwner = false;
        }
      }
    }
    return beneficialOwner;
  };

  signatoryFinished = (signatories) => {
    let signatory = true;
    for (const key1 in signatories) {
      for (const key2 in signatories[key1]) {
        if (
          signatories[key1][key2] === undefined ||
          signatories[key1][key2] === "" ||
          signatories[key1][key2] === null
        ) {
          signatory = false;
        }
      }
    }
    return signatory;
  };

  generateSignatory = (id) => {
    return (
      <Signatory
        signatory={this.props.newDealer.newDealerSignatories[id]}
        formInformation={this.props.formInformation}
        labels={this.props.labels}
        id={id}
        changeHandler={this.handleSignatoryChange}
        key={id}
        className={"invalid"}
        formType={this.props.formType}
        onBoarding={true}
        newDealer={this.props.newDealer}
        params={this.props.params}
      />
    );
  };

  handleChange = (value, id) => {
    if (typeof id !== "string") {
      if (value.startsWith(id.dialCode + "0")) {
        value = id.dialCode + value.slice((id.dialCode + "0").length);
      }
      id = "phoneContactPerson";
      if (!value.startsWith("+")) {
        value = "+" + value;
      }
    }
    let newState = this.checkFieldsFinished();
    if (newState.hasOwnProperty(id)) {
      newState[id] = value !== "";
    }
    let data = {};
    data["newDealerFinished"] =
      Object.values(newState).every((finished) => {
        return finished;
      }) && this.props.newDealer.verifyNewDealer;
    data[id] = value;
    this.props.changeHandler(data);
    this.setState(newState);
  };

  handleCheck = (event) => {
    let data = {};
    this.props.checkHandler(event);
    let newState = this.checkFieldsFinished();

    data["newDealerFinished"] =
      Object.values(newState).every((finished) => {
        return finished;
      }) && this.props.newDealer.verifyNewDealer;
    this.props.changeHandler(data);
  };

  handleSignatoryChange = (index, field, value) => {
    const newSignatories = [...this.props.newDealer.newDealerSignatories];
    newSignatories[index] = { ...newSignatories[index], [field]: value };

    this.props.changeHandler({ newDealerSignatories: newSignatories });

    let data = {};
    let newState = this.checkFieldsFinished();
    data["newDealerFinished"] =
      Object.values(newState).every((finished) => {
        return finished;
      }) && this.props.newDealer.verifyNewDealer;
    this.props.changeHandler(data);
  };

  addSignatory = () => {
    const newSignatories = [
      ...this.props.newDealer.newDealerSignatories,
      {
        emailSignatoryNewDealer: "",
        socialSecurityNumberSignatory: "",
        addressSignatory: "",
        zipCodeSignatory: "",
        citySignatory: "",
      },
    ];
    this.props.changeHandler({ newDealerSignatories: newSignatories });
  };

  removeSignatory = (index) => {
    const newSignatories = this.props.newDealer.newDealerSignatories.filter(
      (_, i) => i !== index
    );
    this.props.changeHandler({ newDealerSignatories: newSignatories });
  };

  handleBeneficialOwnerChange = (index, field, value) => {
    const newBeneficialOwners = [...this.props.newDealer.beneficialOwners];
    newBeneficialOwners[index][field] = value;
    this.props.changeHandler({ beneficialOwners: newBeneficialOwners });
  };

  addBeneficialOwner = () => {
    const newBeneficialOwners = [
      ...this.props.newDealer.beneficialOwners,
      {
        personalNumber: "",
        firstName: "",
        lastName: "",
        ownershipPercentage: "",
        isPEP: false,
      },
    ];
    this.props.changeHandler({ beneficialOwners: newBeneficialOwners });
  };
  removeBeneficialOwner = (index) => {
    const newBeneficialOwners = this.props.newDealer.beneficialOwners.filter(
      (_, i) => i !== index
    );
    this.props.changeHandler({ beneficialOwners: newBeneficialOwners });
  };

  render() {
    return (
      <div>
        <Grid container spacing={2} className="customer_info">
          <Grid item xs={12}>
            <Typography variant="h5">
              {this.props.labels.company_information}
            </Typography>
            <FormHelperText>
              {this.props.labels.company_information_helper}
            </FormHelperText>
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="newDealerName"
              label={this.props.labels.official_name}
              defaultValue={this.props.newDealer.newDealerName}
              changeHandler={this.handleChange}
              tooltip={this.props.labels.tooltip_official_name}
              sx={{ width: "100%" }}
              required={true}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.newDealerName
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="organizationNumber"
              label={this.props.labels.org_number}
              defaultValue={this.props.newDealer.organizationNumber}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.organizationNumber
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="postAddress"
              label={this.props.labels.postal_adress}
              defaultValue={this.props.newDealer.postAddress}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.postAddress
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="nd_zipCode"
              label={this.props.labels.label_zip_code}
              defaultValue={this.props.newDealer.nd_zipCode}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid && !this.state.nd_zipCode
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="nd_city"
              label={this.props.labels.label_city}
              defaultValue={this.props.newDealer.nd_city}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid && !this.state.nd_city
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="contactPerson"
              label={this.props.labels.contact_person}
              defaultValue={this.props.newDealer.contactPerson}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.contactPerson
                  ? "invalid"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={3}>
            <InputText
              id="emailContactPerson"
              label={this.props.labels.label_email}
              defaultValue={this.props.newDealer.emailContactPerson}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.emailContactPerson
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              id="invoiceEmail"
              label={this.props.labels.label_invoice_email}
              defaultValue={this.props.newDealer.invoiceEmail}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.invoiceEmail
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneInput
              country={this.props.params.lang.toLowerCase()}
              countryCodeEditable={false}
              autoFormat={true}
              specialLabel={this.props.labels.phone_number + " *"}
              value={this.props.newDealer.phoneContactPerson}
              onChange={this.handleChange}
              inputStyle={{
                width: "100%",

                borderColor:
                  this.props.formInformation.showInvalid &&
                  this.props.newDealer.phoneContactPerson.length < 5
                    ? "red"
                    : "",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="differentInvoiceAddress"
                  checked={this.props.newDealer.differentInvoiceAddress}
                  onChange={this.handleCheck}
                  name="differentInvoiceAddress"
                />
              }
              label={this.props.labels.label_different_invoice_adress}
            />
          </Grid>
          {this.props.newDealer.differentInvoiceAddress && (
            <>
              <Grid item xs={4}>
                <InputText
                  id="invoiceAddress"
                  label={this.props.labels.label_invoice_adress}
                  defaultValue={this.props.newDealer.invoiceAddress}
                  changeHandler={this.handleChange}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.invoiceAddress
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id="invoiceZipCode"
                  label={this.props.labels.label_zip_code}
                  defaultValue={this.props.newDealer.invoiceZipCode}
                  changeHandler={this.handleChange}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.invoiceZipCode
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id="invoiceCity"
                  label={this.props.labels.label_city}
                  defaultValue={this.props.newDealer.invoiceCity}
                  changeHandler={this.handleChange}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.invoiceCity
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="wantEInvoice"
                  checked={this.props.newDealer.wantEInvoice}
                  onChange={this.handleCheck}
                  name="wantEInvoice"
                />
              }
              label={this.props.labels.label_want_einvoice}
            />
          </Grid>
          {this.props.newDealer.wantEInvoice && (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="differentEInvoiceEmail"
                      checked={this.props.newDealer.differentEInvoiceEmail}
                      onChange={this.handleCheck}
                      name="differentEInvoiceEmail"
                    />
                  }
                  label={this.props.labels.label_different_email_for_invoice}
                />
              </Grid>
              {this.props.newDealer.differentEInvoiceEmail && (
                <Grid item xs={6}>
                  <InputText
                    id="eInvoiceEmail"
                    label={this.props.labels.label_different_einvoice_email}
                    defaultValue={this.props.newDealer.eInvoiceEmail}
                    changeHandler={this.handleChange}
                    required={true}
                    sx={{ width: "100%" }}
                    className={
                      this.props.formInformation.showInvalid &&
                      !this.state.eInvoiceEmail
                        ? "invalid"
                        : ""
                    }
                  />
                </Grid>
              )}
            </>
          )}

          {/* Bank details */}
          <Grid item xs={12}>
            <Typography variant="h5">
              {this.props.labels.label_bankinfo}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <InputText
              id="bankGiroPostGiro"
              label={this.props.labels.label_bankgiro_postgiro}
              defaultValue={this.props.newDealer.bankGiroPostGiro}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.bankGiroPostGiro
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputText
              id="bankAccountNumber"
              label={this.props.labels.bank_number}
              defaultValue={this.props.newDealer.bankAccountNumber}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.bankAccountNumber
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputText
              id="bicSwiftCode"
              label={this.props.labels.bic_swift}
              defaultValue={this.props.newDealer.bicSwiftCode}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.bicSwiftCode
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputText
              id="clearingNumber"
              label={this.props.labels.label_clearingnumber}
              defaultValue={this.props.newDealer.clearingNumber}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.clearingNumber
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputText
              id="ibanNumber"
              label={this.props.labels.iban_number}
              defaultValue={this.props.newDealer.ibanNumber}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid && !this.state.ibanNumber
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputText
              id="bankName"
              label={this.props.labels.bank_name}
              defaultValue={this.props.newDealer.bankName}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid && !this.state.bankName
                  ? "invalid"
                  : ""
              }
            />
          </Grid>

          {/* Husqvarna */}
          <Grid item xs={12}>
            <Typography variant="h5">{"Husqvarna"}</Typography>
          </Grid>
          <Grid item xs={6}>
            <InputText
              id="husqvarnaNumber"
              label={this.props.labels.husqvarna_number}
              defaultValue={this.props.newDealer.husqvarnaNumber}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.husqvarnaNumber
                  ? "invalid"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="hasHusqvarnaPortalUser"
                  checked={this.props.newDealer.hasHusqvarnaPortalUser}
                  onChange={this.handleCheck}
                  name="hasHusqvarnaPortalUser"
                />
              }
              label={this.props.labels.label_has_husqvarna_portaluser}
            />
          </Grid>
          {this.props.newDealer.hasHusqvarnaPortalUser && (
            <>
              <Grid item xs={3}>
                <InputText
                  id="husqvarnaUserFirstName"
                  label={"Förnamn"}
                  defaultValue={this.props.newDealer.husqvarnaUserFirstName}
                  changeHandler={this.handleChange}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.husqvarnaUserFirstName
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <InputText
                  id="husqvarnaUserLastName"
                  label={"Efternamn"}
                  defaultValue={this.props.newDealer.husqvarnaUserLastName}
                  changeHandler={this.handleChange}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.husqvarnaUserLastName
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <InputText
                  id="husqvarnaUserEmail"
                  label={this.props.labels.label_email}
                  defaultValue={this.props.newDealer.husqvarnaUserEmail}
                  changeHandler={this.handleChange}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.husqvarnaUserEmail
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <InputText
                  id="husqvarnaUserId"
                  label={this.props.labels.label_husqvarna_idnumber}
                  defaultValue={this.props.newDealer.husqvarnaUserId}
                  changeHandler={this.handleChange}
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !this.state.husqvarnaUserId
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
            </>
          )}

          {/* Type of financing */}
          <Grid item xs={12}>
            <Typography variant="h5">
              {this.props.labels.label_typeof_financing}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputText
              id="desiredService"
              label={this.props.labels.label_typeof_service}
              defaultValue={this.props.newDealer.desiredService}
              changeHandler={this.handleChange}
              required={true}
              sx={{ width: "100%" }}
              className={
                this.props.formInformation.showInvalid &&
                !this.state.desiredService
                  ? "invalid"
                  : ""
              }
            />
          </Grid>

          {/* Beneficial Owner */}
          <Grid item xs={12}>
            <Typography variant="h5">
              {this.props.labels.label_beneficial_owner}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="hasOwnershipOver25Percent"
                  checked={this.props.newDealer.hasOwnershipOver25Percent}
                  onChange={this.handleCheck}
                  name="hasOwnershipOver25Percent"
                />
              }
              label={this.props.labels.label_ownership_over_25percent}
            />
          </Grid>
          {this.props.newDealer.hasOwnershipOver25Percent && (
            <>
              {this.props.newDealer.beneficialOwners.map((owner, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12}>
                    <TextField
                      id={"beneficialowners_" + index}
                      value={
                        this.props.labels.label_beneficial_owner + (index + 1)
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputText
                      id={`beneficialOwners[${index}].personalNumber`}
                      label={this.props.labels.social_security_number}
                      defaultValue={owner.personalNumber}
                      changeHandler={(value) =>
                        this.handleBeneficialOwnerChange(
                          index,
                          "personalNumber",
                          value
                        )
                      }
                      required={true}
                      sx={{ width: "100%" }}
                      className={
                        this.props.formInformation.showInvalid &&
                        !owner.personalNumber
                          ? "invalid"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputText
                      id={`beneficialOwners[${index}].firstName`}
                      label={this.props.labels.label_first_name}
                      defaultValue={owner.firstName}
                      changeHandler={(value) =>
                        this.handleBeneficialOwnerChange(
                          index,
                          "firstName",
                          value
                        )
                      }
                      required={true}
                      sx={{ width: "100%" }}
                      className={
                        this.props.formInformation.showInvalid &&
                        !owner.firstName
                          ? "invalid"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputText
                      id={`beneficialOwners[${index}].lastName`}
                      label={this.props.labels.label_last_name}
                      defaultValue={owner.lastName}
                      changeHandler={(value) =>
                        this.handleBeneficialOwnerChange(
                          index,
                          "lastName",
                          value
                        )
                      }
                      required={true}
                      sx={{ width: "100%" }}
                      className={
                        this.props.formInformation.showInvalid &&
                        !owner.lastName
                          ? "invalid"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputText
                      id={`beneficialOwners[${index}].ownershipPercentage`}
                      label={this.props.labels.label_ownership_percentage}
                      defaultValue={owner.ownershipPercentage}
                      changeHandler={(value) =>
                        this.handleBeneficialOwnerChange(
                          index,
                          "ownershipPercentage",
                          value
                        )
                      }
                      required={true}
                      sx={{ width: "100%" }}
                      className={
                        this.props.formInformation.showInvalid &&
                        !owner.ownershipPercentage
                          ? "invalid"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={owner.isPEP}
                          onChange={(event) =>
                            this.handleBeneficialOwnerChange(
                              index,
                              "isPEP",
                              event.target.checked
                            )
                          }
                          name={`beneficialOwners[${index}].isPEP`}
                        />
                      }
                      label={this.props.labels.label_is_beneficial_owner_pep}
                    />
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <div className="row padding-Top paddingBottom">
                  <div className="buttonPadding">
                    <Button
                      onClick={this.addBeneficialOwner}
                      startIcon={<AddCircleOutlineIcon />}
                      variant="contained"
                      sx={{
                        backgroundColor: "#273A60",
                      }}
                    >
                      {this.props.labels.label_add_beneficial_owner}
                    </Button>
                  </div>
                  {Object.keys(this.props.newDealer.beneficialOwners).length >
                    1 && (
                    <div className="buttonPadding">
                      <Button
                        onClick={() =>
                          this.removeBeneficialOwner(
                            this.props.newDealer.beneficialOwners.length - 1
                          )
                        }
                        startIcon={<RemoveCircleOutlineIcon />}
                        variant="contained"
                        color="error"
                      >
                        {this.props.labels.label_remove_benficial_owner}
                      </Button>
                    </div>
                  )}
                </div>
              </Grid>
            </>
          )}

          {/* Signatories */}
          <Grid item xs={12}>
            <Typography variant="h5">
              {this.props.labels.label_signatories}
            </Typography>
          </Grid>
          {this.props.newDealer.newDealerSignatories.map((signatory, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <TextField
                  id={"signatory_" + index}
                  value={this.props.labels.label_signatory + " " + (index + 1)}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={6}>
                <InputText
                  id={`signatories[${index}].emailSignatoryNewDealer`}
                  label={this.props.labels.label_email}
                  defaultValue={signatory.emailSignatoryNewDealer}
                  changeHandler={(value) =>
                    this.handleSignatoryChange(
                      index,
                      "emailSignatoryNewDealer",
                      value
                    )
                  }
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !signatory.emailSignatoryNewDealer
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InputText
                  id={`signatories[${index}].socialSecurityNumberSignatory`}
                  label={this.props.labels.social_security_number}
                  defaultValue={signatory.socialSecurityNumberSignatory}
                  changeHandler={(value) =>
                    this.handleSignatoryChange(
                      index,
                      "socialSecurityNumberSignatory",
                      value
                    )
                  }
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !signatory.socialSecurityNumberSignatory
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id={`signatories[${index}].addressSignatory`}
                  label={this.props.labels.postal_adress}
                  defaultValue={signatory.addressSignatory}
                  changeHandler={(value) =>
                    this.handleSignatoryChange(index, "addressSignatory", value)
                  }
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !signatory.addressSignatory
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id={`Postnummer`}
                  label={this.props.labels.zip_code}
                  defaultValue={signatory.zipCodeSignatory}
                  changeHandler={(value) =>
                    this.handleSignatoryChange(index, "zipCodeSignatory", value)
                  }
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !signatory.zipCodeSignatory
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputText
                  id={`signatories[${index}].citySignatory`}
                  label={this.props.labels.label_city}
                  defaultValue={signatory.citySignatory}
                  changeHandler={(value) =>
                    this.handleSignatoryChange(index, "citySignatory", value)
                  }
                  required={true}
                  sx={{ width: "100%" }}
                  className={
                    this.props.formInformation.showInvalid &&
                    !signatory.citySignatory
                      ? "invalid"
                      : ""
                  }
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <div className="row padding-Top paddingBottom">
              <div className="buttonPadding">
                <Button
                  onClick={this.addSignatory}
                  variant="contained"
                  sx={{
                    backgroundColor: "#273A60",
                  }}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  {this.props.labels.label_add_signatory}
                </Button>
              </div>
              {Object.keys(this.props.newDealer.newDealerSignatories).length >
                1 && (
                <div className="buttonPadding">
                  <Button
                    onClick={() =>
                      this.removeSignatory(
                        this.props.newDealer.newDealerSignatories.length - 1
                      )
                    }
                    variant="contained"
                    color="error"
                    startIcon={<RemoveCircleOutlineIcon />}
                  >
                    {this.props.labels.label_remove_signatory}
                  </Button>
                </div>
              )}
            </div>
          </Grid>

          {/* Verification */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="verifyNewDealer"
                  checked={this.props.newDealer.verifyNewDealer}
                  onChange={this.handleCheck}
                  name="verifyNewDealer"
                />
              }
              label={this.props.labels.verify + " *"}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
