import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Box,
  Stack,
  Grid,
  Typography,
} from "@mui/material";
import { sendOrder } from "../sendOrder";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import InputText from "../Fields/InputText";

const contractType = { leaseplus: "Lease Plus" };

export default class DisplayContract extends React.Component {
  static propTypes = {
    creditFile: PropTypes.object,
    contractFile: PropTypes.object,
    labels: PropTypes.object,
    params: PropTypes.object,
    switchFormState: PropTypes.func,
    formInformation: PropTypes.object,
    productsValues: PropTypes.object,
    customerInformation: PropTypes.object,
    accessories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    extraFiles: PropTypes.array,
    products: PropTypes.array,
    variables: PropTypes.object,
    newDealerInformation: PropTypes.object,
    creditError: PropTypes.bool,
    sendContract: PropTypes.func,
    changeHandler: PropTypes.func,
  };

  state = {
    submited: false,
    ownCopy: true,
    sentForSigning: false,
    signingError: false,
    showSummary: false,
  };

  switchFormState = (value) => {
    this.props.switchFormState(value);
  };

  handleChecked = () => {
    this.setState({ ownCopy: !this.state.ownCopy });
  };

  toggleSummary = () => {
    this.setState({ showSummary: !this.state.showSummary });
  };

  serverResponce = (res) => {
    if (res.status === 200) {
      //if the mail was successfuly sent
      this.switchFormState("completed");
    } else {
      //if not
      console.log(res.err);
    }
  };

  handleChange = (value, id) => {
    let newState = this.state;
    let data = {};
    data[id] = value;
    this.props.changeHandler(data);
    this.setState(newState);
  };

  render() {
    return (
      <div>
        <Modal
          open={this.state.showSummary}
          onClose={this.toggleSummary}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              borderRadius: 2,
              p: 4,
              textAlign: "center",
            }}
          >
            <Typography variant="h4" gutterBottom>
              <strong>{this.props.labels.summery_title}</strong>
            </Typography>
            <Box component="section" sx={{ p: 2, border: "2px solid black" }}>
              <Stack spacing={2} style={{ alignItems: "start" }}>
                <Typography gutterBottom>
                  <strong>{this.props.labels.customer}:</strong>{" "}
                  {this.props.customerInformation.customerFirstName +
                    " " +
                    this.props.customerInformation.customerLastName}
                </Typography>
                <Typography gutterBottom>
                  <strong>{this.props.labels.agreement}:</strong>{" "}
                  {contractType[this.props.params.formType.toLowerCase()]}
                </Typography>
                <Typography gutterBottom>
                  <strong>{this.props.labels.monthly_cost}:</strong>{" "}
                  {this.props.formInformation.totalMonthlyFee +
                    " " +
                    this.props.labels.currency +
                    " " +
                    this.props.labels.inc_vat}
                </Typography>
                <Typography gutterBottom>
                  <strong>{this.props.labels.contract_length}:</strong>{" "}
                  {this.props.formInformation.contractLength +
                    " " +
                    this.props.labels.months}
                </Typography>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <Typography gutterBottom>
                    <strong>{this.props.labels.products}:</strong>{" "}
                  </Typography>

                  <Stack
                    direction="column"
                    style={{ marginLeft: "8px", alignItems: "flex-start" }}
                  >
                    {Object.values(this.props.productsValues).map(
                      (product, index) => (
                        <Typography key={index} component="div">
                          {product.name}
                        </Typography>
                      )
                    )}
                  </Stack>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <Typography gutterBottom>
                    <strong>{this.props.labels.accessory}:</strong>{" "}
                  </Typography>

                  <Stack
                    direction="column"
                    style={{ marginLeft: "8px", alignItems: "flex-start" }}
                  >
                    {Object.values(this.props.productsValues).map(
                      (product, index) => (
                        <Typography key={index} component="div">
                          {product.accessory}
                        </Typography>
                      )
                    )}
                  </Stack>
                </div>
                <Typography gutterBottom>
                  <strong>{this.props.labels.dealer}:</strong>{" "}
                  {this.props.customerInformation.dealerName}
                </Typography>
              </Stack>
            </Box>

            <Stack direction="column" alignItems="center" spacing={2}>
              <Typography variant="h6" gutterBottom>
                <strong>{this.props.labels.final_check}</strong>
              </Typography>
              {!this.state.submited ? (
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ mt: 2, backgroundColor: "#273A60" }}
                    onClick={async () => {
                      this.setState({ submited: true });
                      const signing = await this.props.sendContract();
                      if (signing?.error) {
                        alert(
                          "Kunde inte skicka kontraktet för signering, kontrollera att kundens E-post adress är korrekt eller skicka order till HCS för manuell hantering."
                        );
                        this.setState({ signingError: true, submited: false });
                      } else {
                        let data = { ...this.props };
                        data["apiError"] = "";
                        sendOrder(
                          data,
                          this.state.ownCopy,
                          this.serverResponce
                        );
                      }
                    }}
                  >
                    {this.props.labels.positive_answer}
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ mt: 2, backgroundColor: "#273A60" }}
                    onClick={() => {
                      this.setState({ modalOpen: false });
                      this.switchFormState("edit");
                    }}
                  >
                    {this.props.labels.negative_answer}
                  </Button>
                </Stack>
              ) : (
                <Box sx={{ textAlign: "right" }}>
                  <CircularProgress />
                </Box>
              )}
            </Stack>
          </Box>
        </Modal>

        <div className="margin-bot">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {this.props.labels.customer_information_title}{" "}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <InputText
                id="customerEmail"
                label={this.props.labels.label_email}
                defaultValue={this.props.customerInformation.customerEmail}
                changeHandler={this.handleChange}
                sx={{ width: "100%" }}
                required={true}
                className={
                  this.props.formInformation.showInvalid &&
                  !this.state.customerEmail
                    ? "invalid"
                    : ""
                }
              />
            </Grid>
          </Grid>
        </div>
        <div>
          {this.props.contractFile.fileDisplay ? (
            <div style={{ textAlign: "center" }}>
              <object
                data={
                  this.props.contractFile.fileDisplay +
                  "#toolbar=0&navpanes=0&scrollbar=0&amp;page=3&amp;view=FitV"
                }
                width="76%"
                height="1000px"
              >
                <p>
                  Your browser does not support PDFs. Please download the PDF to
                  view it:
                  <a href={this.props.contractFile.fileDisplay}>Download PDF</a>
                  .
                </p>
              </object>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <p>{"Creating contract PDF..."}</p>
            </div>
          )}
          <div>
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  id="quote_check"
                  checked={this.state.ownCopy}
                  onChange={this.handleChecked}
                />
              }
              label={this.props.labels.copy}
            />
          </div>
          {this.state.signingError && (
            <div className="rightButton">
              {!this.state.submited ? (
                <Button
                  sx={{
                    minWidth: "350px",
                    minHeight: "45px",
                    backgroundColor: "#273A60",
                    marginRight: "-8px",
                  }}
                  onClick={async () => {
                    this.setState({ submited: true });
                    let data = { ...this.props };
                    data["apiError"] =
                      "OBS!! Kontraktet kunde inte skickas till Verified för signering.";
                    sendOrder(data, this.state.ownCopy, this.serverResponce);
                  }}
                  variant="contained"
                >
                  {this.props.labels.submit}{" "}
                  <SendIcon sx={{ marginLeft: "15px" }} />
                </Button>
              ) : (
                <Box>
                  <CircularProgress />
                </Box>
              )}
            </div>
          )}
          <div className="button-container">
            <div className="button-container-row">
              <Button
                sx={{ backgroundColor: "#273A60" }}
                onClick={() => {
                  this.switchFormState("edit");
                }}
                variant="contained"
              >
                <ArrowBackRoundedIcon sx={{ marginRight: "5px" }} />
                {this.props.labels.edit_form}
              </Button>

              <Button
                sx={{
                  minWidth: "350px",
                  minHeight: "45px",
                  backgroundColor: "#273A60",
                }}
                onClick={this.toggleSummary}
                variant="contained"
                disabled={this.props.contractFile.fileDisplay ? false : true}
              >
                {this.props.labels.send_for_signing}{" "}
                <SendIcon sx={{ marginLeft: "15px" }} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
