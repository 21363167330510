import React from "react";
import Information from "../component/Information";
import { useParams } from "react-router-dom";
import { getLabels } from "../logic/ServerCalls";
import { getContractOnboardingPDF } from "../logic/ServerCalls";
import { Stack, Button } from "@mui/material";
import NewDealerFloorplan from "../component/Information/Dealer/NewDealerFloorplan";
import NotFound from "../component/NotFound";
import configSource from "../res/config.json";
import config from "./res/config.json";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { sendEmailToAddress } from "../logic/ServerCalls";
import "./css/onboarding.css";
import FloorplanContract from "../component/Contract/OnboardingFloorplanContract";

const ReactDomServer = require("react-dom/server");

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Hcs extends React.Component {
  state = {
    labels: {},
    headerText: "",
    params: {},
    newDealerInformation: {
      newDealerFinished: false,
      verifyNewDealer: false,
      newDealerName: "", // Namn
      organizationNumber: "", // Organisationsnummer
      postAddress: "", // Postadress
      nd_zipCode: "", // Postnummer
      nd_city: "", // Ort
      contactPerson: "", // Kontaktperson
      phoneContactPerson: "", // Telefonnummer
      emailContactPerson: "", // E-postadress
      invoiceEmail: "", // E-post för avisering
      differentInvoiceAddress: false, // Är fakturaadressen en annan än bolagsadressen?
      invoiceAddress: "", // Fakturaadress
      invoiceZipCode: "", // Postnummer (för fakturaadress)
      invoiceCity: "", // Ort (för fakturaadress)
      wantEInvoice: false, // Vill ni ha fakturering via E-faktura (PEPPOL)?
      differentEInvoiceEmail: false, // Är det en annan e-postadress än den för avi/faktura?
      eInvoiceEmail: "", // Ange e-postadress (Peppol)

      // Bankuppgifter
      bankGiroPostGiro: "", // Bankgiro/Postgiro
      bankAccountNumber: "", // Bankkontonummer
      bicSwiftCode: "", // BIC/Swift kod
      clearingNumber: "", // Clearingnummer
      ibanNumber: "", // IBAN
      bankName: "", // Bank namn

      // Husqvarna
      husqvarnaNumber: "", // Husqvarna-kundnummer
      hasHusqvarnaPortalUser: false, // Har ni en användare i Husqvarna-portalen?
      husqvarnaUserFirstName: "", // Förnamn
      husqvarnaUserLastName: "", // Efternamn
      husqvarnaUserEmail: "", // E-postadress
      husqvarnaUserId: "", // Husqvarna användarID-nummer

      // Typ av finansiering
      desiredService: "", // Vilken tjänst önskar ni nyttja?

      // Verklighuvudman
      hasOwnershipOver25Percent: false, // Äger eller kontrollerar några fysiska personer mer än 25% av bolaget?
      beneficialOwners: [
        {
          personalNumber: "", // Personnummer
          firstName: "", // Förnamn
          lastName: "", // Efternamn
          ownershipPercentage: "", // Ägandeandel (%)
          isPEP: false, // Är denna verkliga huvudman en PEP?
        },
      ],

      // Firmatecknare
      newDealerSignatories: [
        {
          emailSignatoryNewDealer: "", // E-postadress
          socialSecurityNumberSignatory: "", // Personnummer
          addressSignatory: "", // Postadress
          zipCodeSignatory: "", // Postnummer
          citySignatory: "", // Ort
        },
      ],
    },
    formInformation: {
      formState: "edit",
      showInvalid: false,
      useCampaign: false,
    },
    extraFiles: [],
    firstRender: true,
  };

  /**
   *  Gets called after first render.
   */
  componentDidMount() {
    if (this.validateParams()) {
      this.setState({
        firstRender: false,
        params: { ...this.props.params, comp: "hcs" },
      });
      this.SetFormInformation();
    }
  }

  /**
   * Requests labels, headerText, productlist and variables from server and puts them in state.
   */
  SetFormInformation = () => {
    (async () => {
      try {
        const lang = this.props.params.lang.toLowerCase();
        let labels;
        labels = await getLabels(lang);
        let state = this.state;

        state.labels = labels;
        state.isVerified = true;

        this.setState(state);
      } catch (error) {
        //TODO redirect
        console.log(error);
      }
    })();
  };

  /**
   *
   * @param {Array<File>} files
   *
   * Puts the files in the array in state.
   */
  addExtraFiles = (files) => {
    let extraFiles = this.state.extraFiles;
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      extraFiles.push(element);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * @param {File} file
   *
   * Removes given file from state if it exists.
   */
  removeExtraFiles = (file) => {
    let extraFiles = this.state.extraFiles;
    let index = extraFiles.indexOf(file);
    if (index !== -1) {
      extraFiles.splice(index, 1);
    }
    this.setState({ extraFiles: extraFiles });
  };

  /**
   *
   * @param {Object} info
   *
   * Updates the value of newDealerInformation based on keys in info.
   */
  handleNewDealerInfoChange = (info) => {
    let newDealerInformation = this.state.newDealerInformation;
    console.log(info);

    for (let id in info) {
      newDealerInformation[id] = info[id];
    }
    this.setState({
      newDealerInformation: newDealerInformation,
    });
  };

  /**
   *
   * @param {Object} event
   *
   * Handles checkbox check depending on case.
   */
  handleChecked = async (event) => {
    let newState;
    let newDealerInformation = this.state.newDealerInformation;
    console.log(event.target);

    switch (event.target.id) {
      case "verifyNewDealer":
        newState = this.state.newDealerInformation.verifyNewDealer
          ? false
          : true;
        newDealerInformation.verifyNewDealer = newState;
        this.setState({ newDealerInformation: newDealerInformation });
        break;
      case "differentInvoiceAddress":
        newState = this.state.newDealerInformation.differentInvoiceAddress
          ? false
          : true;
        newDealerInformation.differentInvoiceAddress = newState;
        this.setState({ newDealerInformation: newDealerInformation });
        break;
      case "wantEInvoice":
        newState = this.state.newDealerInformation.wantEInvoice ? false : true;
        newDealerInformation.wantEInvoice = newState;
        this.setState({ newDealerInformation: newDealerInformation });
        break;
      case "differentEInvoiceEmail":
        newState = this.state.newDealerInformation.differentEInvoiceEmail
          ? false
          : true;
        newDealerInformation.differentEInvoiceEmail = newState;
        this.setState({ newDealerInformation: newDealerInformation });
        break;
      case "hasHusqvarnaPortalUser":
        newState = this.state.newDealerInformation.hasHusqvarnaPortalUser
          ? false
          : true;
        newDealerInformation.hasHusqvarnaPortalUser = newState;
        this.setState({ newDealerInformation: newDealerInformation });
        break;
      case "hasOwnershipOver25Percent":
        newState = this.state.newDealerInformation.hasOwnershipOver25Percent
          ? false
          : true;
        newDealerInformation.hasOwnershipOver25Percent = newState;
        this.setState({ newDealerInformation: newDealerInformation });
        break;

      default:
        throw Error("Unknown button ID");
    }
  };

  formatNewDealerInformation = (newDealerInformation) => {
    const {
      newDealerFinished,
      verifyNewDealer,
      newDealerName,
      organizationNumber,
      postAddress,
      nd_zipCode,
      nd_city,
      contactPerson,
      phoneContactPerson,
      emailContactPerson,
      invoiceEmail,
      differentInvoiceAddress,
      invoiceAddress,
      invoiceZipCode,
      invoiceCity,
      wantEInvoice,
      differentEInvoiceEmail,
      eInvoiceEmail,
      bankGiroPostGiro,
      bankAccountNumber,
      bicSwiftCode,
      clearingNumber,
      ibanNumber,
      bankName,
      husqvarnaNumber,
      hasHusqvarnaPortalUser,
      husqvarnaUserFirstName,
      husqvarnaUserLastName,
      husqvarnaUserEmail,
      husqvarnaUserId,
      desiredService,
      hasOwnershipOver25Percent,
      beneficialOwners,
      newDealerSignatories,
    } = newDealerInformation;

    const beneficialOwnersHTML = beneficialOwners
      .map((owner, index) => {
        return `<p><strong>Beneficial Owner ${index + 1}:</strong><br/>
    - First Name: ${owner.firstName}<br/>
    - Last Name: ${owner.lastName}<br/>
    - Personal Number: ${owner.personalNumber}<br/>
    - Ownership Percentage: ${owner.ownershipPercentage}%<br/>
    - PEP: ${owner.isPEP ? "Yes" : "No"}</p>`;
      })
      .join("");

    const signatoriesHTML = newDealerSignatories
      .map((signatory, index) => {
        return `<p><strong>Signatory ${index + 1}:</strong><br/>
    - Email: ${signatory.emailSignatoryNewDealer}<br/>
    - Social Security Number: ${signatory.socialSecurityNumberSignatory}<br/>
    - Address: ${signatory.addressSignatory}<br/>
    - Zip Code: ${signatory.zipCodeSignatory}<br/>
    - City: ${signatory.citySignatory}</p>`;
      })
      .join("");

    return `
    <div style="font-family: Arial, sans-serif;">
      <h2>New Dealer Information</h2>
      <p><strong>New Dealer Finished:</strong> ${
        newDealerFinished ? "Yes" : "No"
      }</p>
      <p><strong>Verify New Dealer:</strong> ${
        verifyNewDealer ? "Yes" : "No"
      }</p>
      <p><strong>Name:</strong> ${newDealerName}</p>
      <p><strong>Organization Number:</strong> ${organizationNumber}</p>
      <p><strong>Post Address:</strong> ${postAddress}</p>
      <p><strong>Zip Code:</strong> ${nd_zipCode}</p>
      <p><strong>City:</strong> ${nd_city}</p>
      <p><strong>Contact Person:</strong> ${contactPerson}</p>
      <p><strong>Phone Contact Person:</strong> ${phoneContactPerson}</p>
      <p><strong>Email Contact Person:</strong> ${emailContactPerson}</p>
      <p><strong>Invoice Email:</strong> ${invoiceEmail}</p>
      <p><strong>Different Invoice Address:</strong> ${
        differentInvoiceAddress ? "Yes" : "No"
      }</p>
      <p><strong>Invoice Address:</strong> ${invoiceAddress}</p>
      <p><strong>Invoice Zip Code:</strong> ${invoiceZipCode}</p>
      <p><strong>Invoice City:</strong> ${invoiceCity}</p>
      <p><strong>Want E-Invoice:</strong> ${wantEInvoice ? "Yes" : "No"}</p>
      <p><strong>Different E-Invoice Email:</strong> ${
        differentEInvoiceEmail ? "Yes" : "No"
      }</p>
      <p><strong>E-Invoice Email:</strong> ${eInvoiceEmail}</p>
  
      <h3>Bank Details</h3>
      <p><strong>Bank Giro/PostGiro:</strong> ${bankGiroPostGiro}</p>
      <p><strong>Bank Account Number:</strong> ${bankAccountNumber}</p>
      <p><strong>BIC/Swift Code:</strong> ${bicSwiftCode}</p>
      <p><strong>Clearing Number:</strong> ${clearingNumber}</p>
      <p><strong>IBAN Number:</strong> ${ibanNumber}</p>
      <p><strong>Bank Name:</strong> ${bankName}</p>
  
      <h3>Husqvarna Information</h3>
      <p><strong>Husqvarna Number:</strong> ${husqvarnaNumber}</p>
      <p><strong>Has Husqvarna Portal User:</strong> ${
        hasHusqvarnaPortalUser ? "Yes" : "No"
      }</p>
      <p><strong>Husqvarna User First Name:</strong> ${husqvarnaUserFirstName}</p>
      <p><strong>Husqvarna User Last Name:</strong> ${husqvarnaUserLastName}</p>
      <p><strong>Husqvarna User Email:</strong> ${husqvarnaUserEmail}</p>
      <p><strong>Husqvarna User ID:</strong> ${husqvarnaUserId}</p>
  
      <h3>Desired Service</h3>
      <p>${desiredService}</p>
  
      <h3>Ownership Information</h3>
      <p><strong>Has Ownership Over 25%:</strong> ${
        hasOwnershipOver25Percent ? "Yes" : "No"
      }</p>
      ${beneficialOwnersHTML}
  
      <h3>Signatories</h3>
      ${signatoriesHTML}
    </div>
    `;
  };

  /* 
    Uploads data to the Onboaring excel file stored in s3 bucket amplio-shared-files.
  */

  uploadData = async () => {
    /*const csvData = customizeDataForOnboarding(
      this.state.newDealerInformation,
      this.props.params.lang.toLowerCase()
    );
    console.log("CSV data: ", csvData);*/
    //generateContractFloorplan();

    const contract = ReactDomServer.renderToStaticMarkup(
      React.createElement(FloorplanContract, {
        newDealerInformation: this.state.newDealerInformation,
        labels: this.state.labels,
      })
    );
    const additionalPdfUrl =
      configSource.ROOT +
      `getimage?company=hcs&country=${this.state.params.lang.toLowerCase()}&type=lp_tos.pdf`;
    const requestBody = {
      htmlContent: contract,
      additionalPdfUrl: additionalPdfUrl,
    };
    const response = await getContractOnboardingPDF(requestBody);
    if (response.data) {
      const uint8Array = new Uint8Array(response.data);
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      const fileURL = window.URL.createObjectURL(blob);

      // Create a link element, set the download attribute with a filename, and click it to trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "Onboarding.pdf";
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the object URL and removing the link element
      window.URL.revokeObjectURL(fileURL);
      document.body.removeChild(link);
    } else {
      console.error("Failed to generate PDF");
    }

    let flatdata = this.formatNewDealerInformation(
      this.state.newDealerInformation
    );
    const resp = await sendEmailToAddress(
      flatdata,
      "fredrik.naas@ampliorental.se",
      "TEST onboarding"
    );

    if (resp?.status === 200) {
      this.switchFormState("completed");
    } else {
      alert(
        "Information could not be sent, please reload the page and try again."
      );
    }
    this.switchFormState("completed");

    //console.log(resp);

    /*const res = await postOnboarding(
      this.props.params.lang.toLowerCase(),
      csvData
    );
    if (res?.status === 200) {
      this.switchFormState("completed");
    } else {
      alert(
        "Information could not be sent, please reload the page and try again."
      );
    }*/
    //this.switchFormState("completed");
  };

  /**
   *
   * @param {String} value
   *
   * Switches the current formState to new value.
   */
  switchFormState = (value) => {
    let formInformation = this.state.formInformation;
    formInformation.formState = value;
    this.setState(formInformation);
  };

  showInvalid = (newValue = true) => {
    let newFormInformation = this.state.formInformation;
    newFormInformation.showInvalid = newValue;
    this.setState({
      formInformation: newFormInformation,
    });
  };

  /**
   *
   * @returns Boolean
   */
  validateFormInputs = () => {
    if (configSource["DEBUG"]) return true;
    if (!this.state.newDealerInformation.newDealerFinished) {
      this.showInvalid();
      alert("All required fields are not filled in");
      return false;
    }
    this.showInvalid(false);
    return true;
  };

  /**
   *
   * @returns Boolean
   *
   * Check the params to validate that the form requested exists.
   */
  validateParams = () => {
    const allowedArgs = config.ALLOWED_ARGS;
    if (allowedArgs["hcs"].includes(this.props.params.lang.toLowerCase())) {
      return true;
    }
    return false;
  };

  render() {
    if (!this.validateParams()) return <NotFound />;
    if (this.state.firstRender) return;
    const disabled = this.state.newDealerInformation.verifyNewDealer
      ? false
      : true;
    return (
      <Stack className="form-wrapper" alignItems="stretch">
        <div>
          <div className="header">
            <p>{this.state.headerText?.text}</p>
          </div>
          {this.state.formInformation.formState === "edit" && (
            <div>
              <div className="titles-wrapp">
                <h1 className="titles">{this.state.labels.onboarding}</h1>
                <hr />
              </div>
              <div className="order_info">
                <Information
                  label={this.state.labels.onboarding_information}
                  changeHandler={this.handleFormInformationChange}
                ></Information>
              </div>
              <div className="padding-top">
                <NewDealerFloorplan
                  newDealer={this.state.newDealerInformation}
                  labels={this.state?.labels}
                  changeHandler={this.handleNewDealerInfoChange}
                  formInformation={this.state.formInformation}
                  checkHandler={this.handleChecked}
                  params={this.state.params}
                  addSignatory={this.addSignatory}
                  removeSignatory={this.removeSignatory}
                  payment={false}
                />
              </div>
              <div className="button-container-send">
                <Button
                  sx={{
                    minWidth: "350px",
                    minHeight: "45px",
                  }}
                  onClick={() => {
                    if (this.validateFormInputs()) {
                      this.uploadData();
                    }
                  }}
                  variant="contained"
                  disabled={disabled}
                >
                  <ArrowForwardRoundedIcon sx={{ marginLeft: "300px" }} />
                  <span className="app-span-text">
                    {this.state.labels.send}{" "}
                  </span>
                </Button>
              </div>
            </div>
          )}

          {this.state.formInformation.formState === "completed" && (
            <div className="completedText">
              <h1>{this.state.labels.thank_you}</h1>

              <div>
                <p>{this.state.labels.information_sent}</p>
              </div>
            </div>
          )}
          <br></br>
        </div>
      </Stack>
    );
  }
}

export default withParams(Hcs);
